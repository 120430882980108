import React from "react";
import Navbar from "./navbar";

const Header = ({ children, staticHeader, notFullHeight }) => {

  return (
    <header className={notFullHeight ? (staticHeader ? "header static reduce" : "header reduce") : (staticHeader ? "header static" : "header")}>
      { children}
      <div className="container">
        <Navbar />
      </div>
    </header>
  )
};

export default Header
