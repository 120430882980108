import React from 'react'

const Footer = () => {
  return (
    <footer>
      <p className="copyright">Copyright &copy; CoolSo. All rights reserved.</p>
    </footer>
  )
}

export default Footer
