import React from "react";

import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";
import "../style/main.scss";
import Footer from "../components/footer";

const GlobalStyle = createGlobalStyle`
  ${normalize}
  *,p,h1,h2,h3,h4,h5,h6 {
    text-decoration: none;
    margin: 0;
    padding: 0;
    line-height: 1;
  }

  html {
    box-sizing: border-box;
    font-size: 10px;
  }

  body {
    font-family: 'Spartan', sans-serif;
    background: #000;
    color: #000;
    overscroll-behavior: none;
    overflow-x: hidden;
    box-sizing: inherit;
  }

  ul, li {
    list-style: none;
  }

  a {
    color: #000;
  }

  .d-block {
    display: block!important;
  }
`;

const Layout = (props) => {
  return (
    <>
      <GlobalStyle />
      <main props={props}>{props.children}</main>
      <Footer />
    </>
  )
}

export default Layout;
